@use "ms_variables" as ms;

.tache {
    position: absolute;
    opacity: 0.085;
    z-index: 2;
    left: 45%;
    transform: translate3D(var(--posYend), 0px, 0);
}

.tache1 {
    --posYend: #{ ms.$width * -0.08};
    width: 9.3%;
    top: 62.8%;
}

.tache2 {
    --posYend: #{ ms.$width * .143};
    width: 7.3%;
    top: 41.1%;

}

.tache3 {
    --posYend: #{ ms.$width * -.016};
    width: 3.3%;
    top: 52%;
}

.tache4 {
    --posYend: #{ ms.$width * -.083};
    width: 12.6%;
    top: 63.58%;
}

.tache5 {
    --posYend: #{ ms.$width * .133};
    width: 2.3%;
    top: 46.18%;
}

.tache6 {
    --posYend: #{ ms.$width * .226};
    width: 2.6%;
    top: 47.14%;
}