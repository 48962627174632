.paille {
    position: absolute;
    height: auto;
}

.paille {
    z-index: 3;
    width: 7%;
    top: -18%;
    left: 25.6%;
    transform-origin: bottom left;
    transform: rotate(-34deg) translate3d(0, 0, 0);
}