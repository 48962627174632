@use "ms_variables" as ms;
@use "ms_chantilly";
@use "ms_cup";
@use "ms_waves";
@use "ms_spots";
@use "ms_paille";
@use "ms_confettis";

.milkshake {
    position: relative;
    margin: ms.$width * 0.3 auto 0;
    height: ms.$height;
    width: ms.$width;
}

.complete_milkshake {
    background-image: url("img/milkshake/mc_background.png");
    background-size: 100% auto;
    background-position: bottom;
    display: inline-block;
    width: ms.$width * 1.9;
    height: ms.$width * 2.427;
    background-repeat: no-repeat;
    margin: 0 auto;
}