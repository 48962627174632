.chantilly {
    position: absolute;
}

.chantilly_top {
    z-index: 6;
    top: 0%;
    width: 50%;
    left: 27%;
}

.chantilly_middle {
    top: 14.5%;
    z-index: 5;
    width: 83.6%;
    left: 1.6%;
}

.chantilly_bottom {
    top: 19.25%;
    z-index: 5;
    width: 68.6%;
    left: 31%;
}


.chantilly_under {
    width: 100%;
    height: auto;
    top: 31.25%;
    left: 0;
    z-index: 3;
    transform-origin: top;
    opacity: 0.92;

    +.chantilly_under {
        filter: grayscale(0.5) brightness(0.5);
        z-index: 2;
        transform: scaleX(0.9) scaleY(1.2) rotate(2deg);
        opacity: 0.25;

    }
}