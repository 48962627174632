@use "ms_variables" as ms;

.cup {
    position: absolute;
    z-index: 3;
    top: 30.25%;
    bottom: 0;
    width: 100%;
    height: auto;
    left: 0;
}

.cupmask {
    position: absolute;
    z-index: 1;
    top: 30.25%;
    width: 100%;
    height: ms.$width * 1.371;
    mask-image: url(img/milkshake/cup_mask.svg);
    mask-repeat: no-repeat;
    // mask-position: 0px 0px;
    // mask-size: ms.$width ms.$width * 1.371;
    overflow: hidden;
    display: table-cell;
    vertical-align: bottom;

}

.ombre_top {
    position: absolute;
    left: 0;
    top: 31.25%;
    width: ms.$width;
    opacity: 0.3;
    z-index: 6;
}

.border {
    position: absolute;
    top: 33.3%;
    left: 3.3%;
    width: 94%;
    z-index: 6;
}

.ombre_bottom {
    position: absolute;
    top: 34.5%;
    left: 4.6%;
    width: 91%;
    z-index: 5;
    opacity: 0.3;
    transition: opacity 1500ms ease-in;
}
