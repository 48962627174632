@use "ms_variables" as ms;

.wave {
    position: relative;
    transition: all 1s ease;
    height: 100%;
    margin-top: -136.6%;

    background-image: linear-gradient(360deg,
            #000 0,
            #000 calc(-1 * var(--decal_part1)),
            transparent calc(-1 * var(--decal_part1)),
            transparent 100%);

    .ondulations {
        display: flex;
        align-items: end;
        position: absolute;
        top: 0px;
        width: round(ms.$width * 7.4);
        right: 0;

        .regular {
            top: var(--decal_part1);

            &.one {
                width: round(ms.$width * 3.523);
            }

            &.two {
                width: round(ms.$width * 2.64);
            }
        }

        .lastwave {
            width: round(ms.$width * 1.233);
        }

        img {
            position: relative;
            align-self: end;
        }
    }

    //https://codepen.io/sosuke/pen/Pjoqqp
    &.wave1 {
        --decal_part1: 0;
        filter: invert(98%) sepia(2%) saturate(2272%) hue-rotate(323deg) brightness(100%) contrast(88%);
    }

    &.wave2 {
        --decal_part1: #{ ms.$width * -.0866 };
        filter: invert(74%) sepia(30%) saturate(601%) hue-rotate(349deg) brightness(103%) contrast(95%);
    }

    &.wave3 {
        --decal_part1: #{ ms.$width * -.158};
        filter: invert(58%) sepia(99%) saturate(421%) hue-rotate(318deg) brightness(98%) contrast(97%)
    }

    &.wave4 {
        --decal_part1: #{ ms.$width * -.243};
        filter: invert(50%) sepia(41%) saturate(6262%) hue-rotate(327deg) brightness(93%) contrast(96%);
    }

    &.wave5 {
        --decal_part1: #{ ms.$width * -.322};
        filter: invert(77%) sepia(60%) saturate(466%) hue-rotate(157deg) brightness(92%) contrast(83%);
    }

    &.wave6 {
        --decal_part1: #{ ms.$width * -.52};
        filter: invert(38%) sepia(71%) saturate(3113%) hue-rotate(330deg) brightness(107%) contrast(83%);
    }

    &.wave7 {
        --decal_part1: #{ ms.$width * -.663};
        filter: invert(11%) sepia(88%) saturate(1305%) hue-rotate(299deg) brightness(98%) contrast(96%);
    }

    &.wave8 {
        --decal_part1: #{ ms.$width * -.73};
        filter: invert(18%) sepia(22%) saturate(1344%) hue-rotate(160deg) brightness(99%) contrast(91%);
    }

    &.wave9 {
        margin-top: 0;
        --decal_part1: #{ ms.$width * -.91};
        filter: invert(44%) sepia(57%) saturate(488%) hue-rotate(145deg) brightness(86%) contrast(89%);
    }

}