@use "ms_variables" as ms;

.confetti {
    position: absolute;
    z-index: 22;

    &.confetti1 {
        top: 28.5%;
        left: 38%;
        width: 11.6%;
    }

    &.confetti2 {
        top: 30.5%;
        left: 53%;
        width: 6.6%;
    }

    &.confetti3 {
        top: 20.5%;
        left: 26%;
        width: 9%;
    }

    &.confetti4 {
        top: 27%;
        left: 7%;
        width: 6.6%;
    }

    &.confetti5 {
        top: 17.3%;
        left: 58%;
        width: 7.3%;
    }

    &.confetti6 {
        top: 28%;
        left: 71%;
        width: 8%;
    }

    &.confetti7 {
        top: 26%;
        left: 65%;
        width: 6%;
    }

    &.confetti8 {
        top: 6.9%;
        left: 55%;
        width: 6.6%;
    }

    &.confetti9 {
        top: 13%;
        left: 39%;
        width: 6.6%;
    }

    &.confetti10 {
        top: 13.5%;
        left: 64.5%;
        width: 6.5%;
    }

    &.confetti11 {
        top: 21.5%;
        left: 14.5%;
        width: 4.1%;
    }

    &.confetti12 {
        top: 10.94%;
        left: 30%;
        width: 6.6%;
    }

    &.confetti13 {
        top: 18%;
        left: 9%;
        width: 13%;
    }

    &.confetti14 {
        top: 11%;
        left: 48%;
        width: 19%;
    }

    &.confetti15 {
        top: 22%;
        left: 80%;
        width: 9%;
    }



}